import { FC } from "react";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from '../styles/navbar.module.css';

interface ILogoProps {
  width: number;
  height: number;
}

const NavBarLogo: FC<ILogoProps> = (props) => {
  return <>
    <Navbar.Brand className={styles.logo}>
        <Link className={`font-manrope ${styles.navLogoLink}`} to='/'>
          <svg xmlns="http://www.w3.org/2000/svg" width={`${props.width}`} height={`${props.height}`} viewBox={`0 0 ${props.width} ${props.height}`} fill="none">
            <g clipPath="url(#clip0_1313_219)">
              <path d="M133.503 21.2821C133.503 10.4357 142.392 1.64084 153.358 1.64084C161.065 1.64084 167.745 5.98407 171.035 12.3305C167.807 5.56794 160.85 0.887939 152.787 0.887939C141.646 0.887939 132.615 9.82213 132.615 20.8428C132.615 30.5512 139.623 38.6376 148.909 40.4279C140.085 38.4286 133.503 30.615 133.503 21.2821Z" fill="#1D1D1B"/>
              <path d="M159.897 25.3677C159.795 25.511 159.83 25.6852 159.977 25.7897C160.045 25.8361 160.122 25.8593 160.194 25.8516C160.278 25.8439 160.352 25.7993 160.405 25.7258C160.505 25.5826 160.471 25.4045 160.323 25.3039C160.178 25.1993 159.998 25.2264 159.897 25.3677Z" fill="#1D1D1B"/>
              <path d="M136.379 6.67293C136.43 6.60325 136.449 6.52003 136.432 6.44648C136.414 6.37873 136.369 6.3168 136.307 6.27228C136.166 6.17357 135.992 6.19874 135.896 6.33422C135.8 6.47164 135.832 6.64196 135.974 6.73874C136.039 6.78325 136.113 6.80454 136.183 6.7968C136.254 6.79099 136.328 6.74454 136.379 6.67293Z" fill="#1D1D1B"/>
              <path d="M148.183 18.8219C148.058 18.7348 147.911 18.76 147.825 18.878C147.739 18.9961 147.766 19.1393 147.891 19.2264C148.017 19.3154 148.163 19.2941 148.249 19.1741C148.335 19.0541 148.308 18.9109 148.183 18.8219Z" fill="#1D1D1B"/>
              <path d="M154.11 26.9589C153.853 26.9376 153.591 27.077 153.407 27.3344C153.223 27.5918 153.176 27.8841 153.284 28.1144C153.354 28.2673 153.523 28.457 153.92 28.4976L154.239 28.5305C154.444 28.5499 154.646 28.455 154.769 28.2847C154.892 28.1144 154.914 27.8918 154.826 27.7079L154.689 27.4215C154.518 27.0654 154.28 26.9763 154.11 26.9589Z" fill="#1D1D1B"/>
              <path d="M145.968 34.0115C145.763 34.298 145.896 34.5863 146.107 34.7315C146.223 34.8109 146.354 34.8476 146.481 34.836C146.622 34.8244 146.751 34.747 146.841 34.6212C147.045 34.3328 146.911 34.0444 146.702 33.8992C146.589 33.8199 146.456 33.7812 146.328 33.7928C146.186 33.8063 146.058 33.8838 145.968 34.0115Z" fill="#1D1D1B"/>
              <path d="M140.218 13.1742C140.234 13.2342 140.273 13.2884 140.328 13.3252C140.453 13.4142 140.601 13.3929 140.685 13.2729C140.769 13.1529 140.742 13.0078 140.617 12.9188C140.56 12.88 140.496 12.8626 140.435 12.8665C140.296 12.882 140.183 13.0388 140.218 13.1742Z" fill="#1D1D1B"/>
              <path d="M130.665 11.1382C130.481 11.3956 130.434 11.6879 130.539 11.9182C130.612 12.0711 130.778 12.2608 131.177 12.3014L131.496 12.3363C131.7 12.3556 131.903 12.2608 132.026 12.0905C132.148 11.9201 132.171 11.6995 132.083 11.5137L131.946 11.2272C131.776 10.8672 131.537 10.7782 131.367 10.7608C131.113 10.7376 130.85 10.8788 130.665 11.1382Z" fill="#1D1D1B"/>
              <path d="M175.115 12.9576C175.357 12.6169 175.279 12.1931 174.927 11.9473C174.577 11.7034 174.144 11.7692 173.902 12.1098C173.661 12.4486 173.739 12.8724 174.089 13.1202C174.442 13.364 174.872 13.2963 175.115 12.9576Z" fill="#1D1D1B"/>
              <path d="M154.569 2.84258C155.029 2.19806 154.734 1.55161 154.264 1.22258C154.014 1.04645 153.718 0.965158 153.435 0.99032C153.112 1.02129 152.824 1.19355 152.621 1.47806C152.161 2.12064 152.456 2.76903 152.926 3.09613C153.176 3.27226 153.472 3.35548 153.755 3.33032C154.078 3.29935 154.368 3.12709 154.569 2.84258Z" fill="#1D1D1B"/>
              <path d="M158.654 0.883938C158.698 1.05426 158.805 1.20716 158.962 1.31749C159.312 1.56136 159.744 1.49555 159.987 1.15684C160.229 0.818132 160.149 0.392325 159.799 0.146519C159.644 0.0381319 159.463 -0.0141263 159.284 0.00329308C158.878 0.0400673 158.555 0.491035 158.654 0.883938Z" fill="#1D1D1B"/>
              <path d="M127.873 38.7051C128.115 38.3645 128.037 37.9406 127.685 37.6948C127.335 37.451 126.902 37.5168 126.66 37.8574C126.417 38.1961 126.497 38.6219 126.847 38.8677C127.2 39.1116 127.63 39.0439 127.873 38.7051Z" fill="#1D1D1B"/>
              <path d="M138.557 13.5673C138.95 13.0157 138.708 12.4002 138.281 12.1021C137.857 11.8041 137.209 11.7479 136.792 12.3325C136.399 12.886 136.64 13.5034 137.068 13.7996C137.491 14.0976 138.14 14.1518 138.557 13.5673Z" fill="#1D1D1B"/>
              <path d="M138.375 29.2232C138.665 28.8207 138.565 28.2961 138.146 28.002C137.73 27.7097 137.195 27.7929 136.908 28.1955C136.62 28.6 136.718 29.1226 137.137 29.4168C137.553 29.709 138.086 29.6258 138.375 29.2232Z" fill="#1D1D1B"/>
              <path d="M151.509 42.4853C151.64 42.4118 151.754 42.3053 151.846 42.1795C152.11 41.8098 152.137 41.2989 151.91 40.9427C151.699 40.606 151.314 40.4472 150.877 40.5189C150.508 40.5769 150.304 40.7627 150.2 40.9079C149.889 41.3414 150.036 41.9782 150.351 42.3189C150.666 42.6595 151.098 42.7214 151.509 42.4853Z" fill="#1D1D1B"/>
              <path d="M136.483 33.9071C136.534 33.878 136.579 33.8374 136.616 33.7851C136.724 33.6341 136.736 33.429 136.646 33.2896C136.563 33.158 136.415 33.098 136.241 33.1271C136.121 33.1445 136.033 33.1967 135.975 33.2761C135.851 33.4483 135.914 33.7058 136.041 33.8432C136.162 33.9748 136.323 34 136.483 33.9071Z" fill="#1D1D1B"/>
              <path d="M126.723 18.5626L126.655 18.4193C126.592 18.2897 126.512 18.2548 126.458 18.249C126.282 18.2355 126.082 18.5142 126.155 18.6709C126.178 18.7213 126.237 18.7871 126.383 18.7987L126.542 18.8142C126.604 18.8219 126.667 18.7929 126.706 18.7387C126.743 18.6864 126.751 18.6206 126.723 18.5626Z" fill="#1D1D1B"/>
              <path d="M153.482 6.02656C153.352 5.93559 153.08 5.85236 152.891 6.11752C152.716 6.36333 152.861 6.59946 153.002 6.69817C153.17 6.81623 153.429 6.83946 153.593 6.6072C153.767 6.3614 153.622 6.12527 153.482 6.02656Z" fill="#1D1D1B"/>
              <path d="M138.719 47.3337C138.828 47.1827 138.791 46.9872 138.633 46.8769C138.473 46.7646 138.277 46.7956 138.168 46.9485C138.06 47.1034 138.095 47.293 138.256 47.4053C138.414 47.5175 138.609 47.4866 138.719 47.3337Z" fill="#1D1D1B"/>
              <path d="M12.4104 24.9639V9.49548H6.40781V6.61548H21.5161V9.49548H15.5154V24.9639H12.4104Z" fill="black"/>
              <path d="M31.4218 25.3451C29.5671 25.3451 27.9784 24.9444 26.6558 24.1412C25.3331 23.338 24.3177 22.2193 23.6094 20.7831C22.9012 19.347 22.5471 17.6825 22.5471 15.7876C22.5471 13.8928 22.9012 12.2283 23.6094 10.7922C24.3177 9.35603 25.3331 8.23732 26.6558 7.4341C27.9784 6.63087 29.5671 6.23022 31.4218 6.23022C33.2766 6.23022 34.8673 6.63087 36.1938 7.4341C37.5203 8.23732 38.5377 9.35603 39.246 10.7922C39.9542 12.2283 40.3084 13.8928 40.3084 15.7876C40.3084 17.6825 39.9542 19.347 39.246 20.7831C38.5377 22.2193 37.5203 23.338 36.1938 24.1412C34.8673 24.9444 33.2766 25.3451 31.4218 25.3451ZM31.4218 22.4534C32.6662 22.4612 33.7031 22.1902 34.5327 21.6386C35.3603 21.087 35.9844 20.3089 36.3992 19.3064C36.816 18.3038 37.0234 17.1328 37.0234 15.7896C37.0234 14.4483 36.816 13.2812 36.3992 12.2922C35.9825 11.3031 35.3603 10.5328 34.5327 9.97926C33.7031 9.42764 32.6662 9.14313 31.4218 9.12571C30.1775 9.11797 29.1425 9.38893 28.3168 9.94055C27.4931 10.4922 26.8729 11.2702 26.4562 12.2728C26.0394 13.2754 25.8321 14.4483 25.8321 15.7896C25.8321 17.1309 26.0375 18.298 26.4503 19.287C26.8631 20.276 27.4834 21.0483 28.311 21.5999C29.1405 22.1515 30.1775 22.436 31.4218 22.4534Z" fill="black"/>
              <path d="M42.8852 24.9639V6.61548H46.0273L54.6321 19.5484V6.61548H57.7743V24.9639H54.6321L46.0273 12.031V24.9639H42.8852Z" fill="black"/>
              <path d="M61.6384 24.9639V6.61548H64.7434V24.9639H61.6384Z" fill="black"/>
              <path d="M76.2575 25.3453C75.0562 25.3453 73.9293 25.1344 72.8767 24.7144C71.8241 24.2944 70.9025 23.677 70.1082 22.8602C69.3138 22.0453 68.6936 21.0447 68.2475 19.8602C67.8015 18.6757 67.5784 17.3189 67.5784 15.7899C67.5784 13.7847 67.956 12.0718 68.7112 10.6492C69.4665 9.22663 70.5015 8.13502 71.8143 7.37437C73.1271 6.61373 74.6102 6.23438 76.2575 6.23438C78.5408 6.23438 80.3506 6.75889 81.6869 7.80792C83.0212 8.85696 83.9252 10.3279 84.3986 12.2228L81.231 12.7202C80.8788 11.6324 80.3036 10.7634 79.5054 10.115C78.7071 9.4647 77.6897 9.13954 76.4532 9.13954C75.2089 9.12212 74.1738 9.38921 73.3502 9.94276C72.5265 10.4944 71.9062 11.2724 71.4895 12.275C71.0728 13.2776 70.8654 14.4505 70.8654 15.7918C70.8654 17.1331 71.0708 18.3002 71.4836 19.2892C71.8965 20.2782 72.5167 21.0505 73.3443 21.6021C74.1719 22.1537 75.2088 22.4382 76.4552 22.4557C77.3904 22.4634 78.2102 22.297 78.9145 21.9524C79.6188 21.6079 80.1941 21.0853 80.6402 20.3847C81.0862 19.6841 81.3875 18.8073 81.5421 17.7544H78.2708V15.3428H84.8388C84.8565 15.4782 84.8682 15.6776 84.878 15.9408C84.8858 16.2041 84.8917 16.3608 84.8917 16.4131C84.8917 18.1453 84.5454 19.686 83.8547 21.0312C83.1641 22.3782 82.1741 23.4331 80.8867 24.1976C79.5934 24.9641 78.0517 25.3453 76.2575 25.3453Z" fill="black"/>
              <path d="M87.4625 24.9639V6.61548H90.5675V14.3361H99.3523V6.61548H102.444V24.9639H99.3523V17.2161H90.5675V24.9639H87.4625Z" fill="black"/>
              <path d="M110.504 24.9639V9.49548H104.502V6.61548H119.61V9.49548H113.608V24.9639H110.504Z" fill="black"/>
              <path d="M37.8002 41.0829V32.6287H41.1772C41.2594 32.6287 41.3591 32.6306 41.4746 32.6383C41.59 32.6441 41.6996 32.6577 41.8072 32.677C42.2689 32.7467 42.6583 32.9054 42.9733 33.1493C43.2883 33.3932 43.525 33.7009 43.6815 34.0706C43.84 34.4403 43.9183 34.8487 43.9183 35.2938C43.9183 35.7429 43.8381 36.1532 43.6776 36.5229C43.5172 36.8925 43.2805 37.1983 42.9655 37.4422C42.6505 37.6841 42.265 37.8409 41.8052 37.9125C41.6976 37.928 41.5881 37.9396 41.4726 37.9474C41.3572 37.9551 41.2594 37.959 41.1752 37.959H38.8568V41.0829H37.8002ZM38.8568 36.9661H41.142C41.2163 36.9661 41.3024 36.9622 41.4002 36.9545C41.4961 36.9467 41.5881 36.9332 41.6761 36.9138C41.9481 36.8519 42.1711 36.7377 42.3413 36.5712C42.5115 36.4048 42.6368 36.2093 42.7189 35.9848C42.7991 35.7603 42.8402 35.53 42.8402 35.2958C42.8402 35.0616 42.7991 34.8312 42.7189 34.6067C42.6387 34.3822 42.5115 34.1867 42.3413 34.0203C42.1711 33.8538 41.95 33.7396 41.6761 33.6777C41.59 33.6583 41.4981 33.6448 41.4002 33.637C41.3024 33.6293 41.2163 33.6254 41.142 33.6254H38.8568V36.9661Z" fill="black"/>
              <path d="M45.1057 41.0829V32.6287H50.5057V33.6216H46.1622V36.2693H49.7935V37.2622H46.1622V40.0919H50.5057V41.0848H45.1057V41.0829Z" fill="black"/>
              <path d="M51.9301 41.0829V32.6287H55.307C55.3892 32.6287 55.489 32.6306 55.6044 32.6383C55.7198 32.6441 55.8294 32.6577 55.937 32.677C56.3987 32.7467 56.7881 32.9054 57.1031 33.1493C57.4181 33.3932 57.6529 33.7009 57.8114 34.0706C57.9698 34.4403 58.0481 34.8487 58.0481 35.2938C58.0481 35.9519 57.8759 36.519 57.5316 36.997C57.1872 37.4751 56.6824 37.7732 56.0192 37.8951L55.6161 37.959H52.9866V41.0829H51.9301ZM52.9866 36.9661H55.2718C55.3461 36.9661 55.4322 36.9622 55.5301 36.9545C55.6259 36.9467 55.7198 36.9332 55.8059 36.9138C56.0779 36.8519 56.3009 36.7377 56.4711 36.5712C56.6414 36.4048 56.7666 36.2093 56.8468 35.9848C56.929 35.7603 56.9681 35.53 56.9681 35.2958C56.9681 35.0616 56.927 34.8312 56.8468 34.6067C56.7666 34.3822 56.6394 34.1867 56.4711 34.0203C56.3009 33.8538 56.0798 33.7396 55.8059 33.6777C55.7198 33.6583 55.6259 33.6448 55.5301 33.637C55.4322 33.6293 55.3461 33.6254 55.2718 33.6254H52.9866V36.9661ZM57.017 41.0829L55.3324 37.6416L56.3772 37.3241L58.2281 41.0809H57.017V41.0829Z" fill="black"/>
              <path d="M59.5332 41.0829V32.6287H64.5184V33.6738H60.5897V36.3332H63.8062V37.3783H60.5897V41.0829H59.5332Z" fill="black"/>
              <path d="M68.9577 41.2587C68.2964 41.2587 67.7134 41.1232 67.2066 40.8542C66.6999 40.5832 66.3027 40.2058 66.0171 39.7181C65.7295 39.2303 65.5866 38.6594 65.5866 38.0013V32.6323L66.6549 32.6264V37.9103C66.6549 38.309 66.7234 38.6574 66.8603 38.9516C66.9973 39.2477 67.1773 39.4935 67.4023 39.689C67.6273 39.8845 67.8758 40.0297 68.1477 40.1264C68.4177 40.2213 68.6877 40.2697 68.9577 40.2697C69.2316 40.2697 69.5036 40.2213 69.7736 40.1226C70.0436 40.0258 70.2921 39.8787 70.5151 39.6852C70.7381 39.4916 70.9181 39.2458 71.0551 38.9477C71.1921 38.6497 71.2605 38.3032 71.2605 37.9084V32.6245H72.3288V37.9974C72.3288 38.6516 72.186 39.2226 71.8984 39.7123C71.6108 40.2019 71.2155 40.5813 70.7088 40.8503C70.2021 41.1232 69.6171 41.2587 68.9577 41.2587Z" fill="black"/>
              <path d="M73.99 41.0828V32.6286H74.9506L78.1789 39.3622L81.3915 32.6267H82.3639V41.0751H81.3602V34.9222L78.4587 41.0809H77.8952L74.9937 34.9222V41.0809H73.99V41.0828Z" fill="black"/>
              <path d="M84.0249 41.0829V32.6287H89.4249V33.6216H85.0814V36.2693H88.7127V37.2622H85.0814V40.0919H89.4249V41.0848H84.0249V41.0829Z" fill="black"/>
            </g>
            <defs>
              <clipPath id="clip0_1313_219">
                <rect width={`${props.width}`} height={`${props.height}`} fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </Link>
    </Navbar.Brand>
  </>
}

export default NavBarLogo;