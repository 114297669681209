import { FC, useEffect, useState } from 'react'

import styles from './volume.pills.module.css';

interface IVolumePills {
    handleChangeVolume?: (value: number) => void;
    initialVolume: number;
    volumesArray: number[];
}

const volumes = [
    {id: 1, value: 2, key: 111}, 
    {id: 2, value: 5, key: 222}, 
    {id: 3, value: 8, key: 333}, 
    {id: 4, value: 10, key: 444}, 
    {id: 5, value: 15, key: 555}];

const VolumePills: FC<IVolumePills> = (props) => {
    const [activePill, setActivePill] = useState(props.initialVolume)
    const [pills, setPills] = useState<{id: number, value: number}[]>([]);

    const handleActive = (value: number) => {
        setActivePill(value);
        if(props.handleChangeVolume) {
            props.handleChangeVolume(value);
        }
    }

    useEffect(() => {
        volumes.map((volume, index) => {
            props.volumesArray.map((item) => {
                if(volume.id === item) {
                    setPills((prev) => [...prev, {id: volume.id, value: volume.value}])
                }
        })})
    }, [props.volumesArray])

    return <div className={styles.volumePills}>
        {pills.map((item, index) => (
            <div 
                key={index}
                className={`${activePill === item.id ? styles.active : styles.default} ${styles.volumePill}`}
                onClick={() => handleActive(item.id)}
            >   
                {item.value} мл
            </div>
        ))}
    </div>
}

export default VolumePills;